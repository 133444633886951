import React from 'react';
import styled from '@emotion/styled';
import mapdataPl from '@assets/json/mapdata_pl.json';
import mapdataUa from '@assets/json/mapdata_ua.json';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Cluster, MarkerClusterer } from "@googlemaps/markerclusterer";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { renderToString } from 'react-dom/server'

import markerIcon from '@assets/img/marker.png';
import markerCluster from '@assets/img/cluster.png';
import { fonts, colors, breakpoints, respondFrom } from '@styles';
import { css } from '@emotion/react';

interface MapProps extends google.maps.MapOptions {
    style?: { [key: string]: string };
    mapId?: string;
    children?: React.ReactNode;
}
interface MapWrapProps extends MapProps {
    apiKey: string;
}
interface InfoContentProps {
    row: Array<string>
}


export const Amount = styled.strong`
    font-family: 'Arial';
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 11px;
    ${respondFrom(breakpoints.md, css`
        font-size: 14px;
        line-height: 16px;
    `)}
`

function getLatLngFromRow(row) {
    return new window.google.maps.LatLng(row[0], row[1])
}

const InfoContent = ({ row }: InfoContentProps) => {
    const beneficiary = 
        row[6] ? <img css={css`margin-bottom: 40px; max-width:200px !important;`} src={`/logotypes/${row[6]}`} /> 
        : (<div css={css`margin-bottom: 10px;`} >{row[5]}</div>);
    return (
        <div className="gm__info-window">
            {beneficiary}
            <h3>{row[2]}</h3>
            <p>{row[3]}</p>
            <Amount>{row[4]}</Amount>
        </div>
    )
}

function getInfoContent(row) {
    return renderToString((
        <InfoContent row={row}></InfoContent>
    ))
}

const Map = ({
    children,
    style,
    ...options
}: MapProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
    const { t } = useTranslation();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, options));
        }
    }, [ref, map]);

    const infoWindow = new google.maps.InfoWindow();

    const onMarkerClick = (row, marker) => {
        const rowClone = { ...row }
        rowClone[4] = `${t('home_map_initiative_amount')}: ${row[4]}`
        infoWindow.setContent(getInfoContent(rowClone));
        infoWindow.open(map, marker);
    }

    const addMarker = (row) => {
        
        const marker = new google.maps.Marker({
            position: getLatLngFromRow(row),
            icon: { url: markerIcon, scaledSize: window.innerWidth < 768 ? new google.maps.Size(20, 26) : new google.maps.Size(40, 53) },
            title: row[3]
        });

        marker.addListener("click", onMarkerClick.bind(this, row, marker));

        return marker;
    }

    const markers = t('language') === 'pl' ? mapdataPl.map(addMarker) : mapdataUa.map(addMarker);

    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < markers.length; i++) {
        bounds.extend(markers[i].position);
    }

    map?.fitBounds(bounds, 0);

    const renderer = {
        render: ({ count, position }) =>
            new google.maps.Marker({
                position,
                icon: {
                    url: markerCluster,
                    scaledSize: window.innerWidth < 768 ? new google.maps.Size(30, 30) : new google.maps.Size(60, 60)
                },
                label: {
                    text: String(count),
                    color: "#242424",
                    fontSize: window.innerWidth < 768 ? "10px" : "20px",
                    fontFamily: `${fonts.extraBold}`
                },
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            })
    };

    new MarkerClusterer({
        markers, map, renderer,
        onClusterClick: (_: google.maps.MapMouseEvent,
            cluster: Cluster,
            map: google.maps.Map) => {
            infoWindow.close()
            map.fitBounds(cluster.bounds as google.maps.LatLngBounds, 30);
        }
    })


    return (
        <div ref={ref} style={style} />
    );
};

const MapWrap = ({
    children,
    style,
    apiKey,
    ...options
}: MapWrapProps) => {
    const MapWrap = styled.div`
        position: relative;
        display: flex;
        height: 50vh;
        @media (orientation: landscape) {
            height: 100vh;
        }
        .gm-style {
            .gm-style-iw-t {
                margin-bottom: 13px;
            }
            .gm-style-iw-c {
                border-radius: 0;
                box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.25);
            }
            .gm-ui-hover-effect {
                top: 2px !important;
                right: 4px !important;
                span {
                    width: 18px !important;
                    height: 18px !important;
                }

            }
        }
        .gm__info-window {
            width: auto;
            max-width: ${294 - 30 * 2}px;
            text-align: left;
            padding: ${40 - 12}px ${30 - 12}px;
            font-size: 16px;
            line-height: 22px;
            font-family: ${fonts.default};
            font-weight: 400;
            color: ${colors.text.default};
            ${respondFrom(breakpoints.md, css`
                width: ${294 - 30 * 2}px;
            `)}
        }
        ${respondFrom(breakpoints.md, css`
            height: 860px;
        `)}
    `
    return (
        <MapWrap>
            <Wrapper apiKey={apiKey}>
                <Map style={style} {...options}>{children}</Map>
            </Wrapper>
        </MapWrap>
    );
}
export default MapWrap;